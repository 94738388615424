import { AiFlowCrmSubscription } from '@geeesy/type-aiflow';

export interface IEnv {
  production: boolean;
  phase: string;
  loggingEnabled: boolean;
  api: {
    baseUrl: string;
  };
  auth0: {
    domain: string;
    clientId: string;
    authorizationParams: {
      redirect_uri: string;
      audience: string;
    };
  };
  s3: {
    fileBlob: string;
    pubBlob: string;
    snapBlob: string;
  };
}

//////////// FEATURE INTERFACE //////////
export interface IFeatureAtt {
  name:
    | AiFlowCrmSubscription.TFeatureLINE
    | AiFlowCrmSubscription.TFeatureBusiness
    | AiFlowCrmSubscription.TFeatureMedia
    | AiFlowCrmSubscription.TFeatureChat
    | AiFlowCrmSubscription.TFeatureAPI;
}

export interface IFeatureLimitAtt {
  name:
    | AiFlowCrmSubscription.TFeatureLINE
    | AiFlowCrmSubscription.TFeatureBusiness
    | AiFlowCrmSubscription.TFeatureMedia
    | AiFlowCrmSubscription.TFeatureChat
    | AiFlowCrmSubscription.TFeatureAPI;
  keyLimit:
    | AiFlowCrmSubscription.TLimitTypeCompany
    | AiFlowCrmSubscription.TLimitTypeLINEConnect
    | AiFlowCrmSubscription.TLimitTypeFileManager;
}
/////////////////////////////////////////

/////////// LINE INTERFACE /////////////
export type TMessagePush =
  | TMessageJson
  | TMessageText
  | TMessageImage
  | TMessageVideo
  | TMessageAudio
  | TMessageLocation
  | TMessageSticker
  | TMessageFile
  | TMessageFlex
  | TMessageFlexImage;

export enum LINE_MESSAGE_TYPE {
  JSON = 'json',
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  LOCATION = 'location',
  STICKER = 'sticker',
  FILE = 'file',
  FLEX = 'flex',
  FLEX_IMAGE = 'flexImage',
  SELECT = 'select',
  IMAGE_MAP = 'imagemap',
  TEMPLATE = 'template'
}

interface TMessageJson {
  type: LINE_MESSAGE_TYPE.JSON;
  json: any;
}

export interface TMessageText {
  type: LINE_MESSAGE_TYPE.TEXT;
  text: string;
  emojiList?: IEmoji[];
  aliasNameList?: IAliasNameList[];
  innerHtml?: string;
}

export interface TMessageImage {
  type: LINE_MESSAGE_TYPE.IMAGE;
  attachments: [
    {
      payload: {
        url: string;
        previewUrl: string;
      };
    }
  ];
}

export interface TMessageVideo {
  type: LINE_MESSAGE_TYPE.VIDEO;
  attachments: [
    {
      payload: {
        url: string;
        previewUrl: string;
        duration: number;
      };
    }
  ];
}

export interface TMessageAudio {
  type: LINE_MESSAGE_TYPE.AUDIO;
  attachments: [
    {
      payload: {
        url: string;
        duration: number;
      };
    }
  ];
}

export interface TMessageLocation {
  type: LINE_MESSAGE_TYPE.LOCATION;
  attachments: [
    {
      payload: {
        title: string;
        address: string;
        latitude: string;
        longitude: string;
      };
    }
  ];
}

export interface TMessageSticker {
  type: LINE_MESSAGE_TYPE.STICKER;
  attachments: [
    {
      payload: {
        packageId: string;
        stickerId: string;
      };
    }
  ];
}

export interface TMessageFile {
  type: LINE_MESSAGE_TYPE.FILE;
  attachments: [
    {
      payload: {
        url: string;
        fileName: string;
        fileSize: number;
      };
    }
  ];
}

export interface TMessageFlex {
  type: LINE_MESSAGE_TYPE.FLEX;
  altText: string;
  contents: any; // ##dynamic element dom##
}

export interface TMessageFlexImage {
  type: LINE_MESSAGE_TYPE.FLEX_IMAGE;
  altText: string;
  contents: TFlexImage;
  contentsJson: any;
}

export interface TFlexImage {
  imageUrl: string;
  hasBanner: boolean;
  bannerText: string;
  bannerColor: string;
  hasAction: boolean;
  actionText: string;
  action?: TFlexImageActionText | TFlexImageActionLink | undefined;
}

export interface TFlexImageActionText {
  type: 'text';
  text: string;
}

export interface TFlexImageActionLink {
  type: 'link';
  uri: string;
}

export interface IEmoji {
  index: number;
  emojiId: string;
}

export type TAliasNameType = 'customerName' | 'marketName';

export interface IAliasNameList {
  index: number;
  type: TAliasNameType;
  value?: string;
}

export interface IPageRenderState {
  isFirstLoadOnSuccessNewUser: boolean;
  isFirstLoadAfterLinkAccount: boolean;
}
